<template>
  <div v-loading="loading">
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>课堂</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/enterprise' }"
          >企业管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ detail.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="margin-sm">
      <div class="fl-r">
        <el-button
          size="small"
          type="primary"
          @click="
            $router.push('/enterprise/details/' + detail.id + '/detailsAdd')
          "
          >新增
        </el-button>
      </div>
      <el-table :data="detail.mchid_list" style="width: 100%">
        <el-table-column label="ID" prop="id" width="80" />
        <el-table-column label="商户号" prop="mchid" />
        <el-table-column label="类型">
          <template slot-scope="scope">
            {{
              scope.row.type === 1
                ? "微信"
                : scope.row.type === 2
                ? "支付宝"
                : "抖音"
            }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="created_at" />
        <el-table-column label="更新时间" prop="updated_at" />
        <el-table-column label="操作" property="address">
          <template slot-scope="scope">
            <el-link
              type="primary"
              @click="
                $router.push(
                  '/enterprise/details/' +
                    detail.id +
                    '/detailsEdit/' +
                    scope.row.id
                )
              "
              >编辑
            </el-link>
            -
            <span>
              <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                title="删除后将不可恢复，确定删除吗？"
                @confirm="enterpriseDel(scope.row.id)"
              >
                <el-link slot="reference" type="primary">删除</el-link>
              </el-popconfirm>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "detail",
  data() {
    return {
      loading: false,
      imageUrl: config.imageUrl,
      id: "",
      detail: {},
    };
  },
  methods: {
    ...mapActions("enterprise", ["detailEnterprise", "delEnterpriseMchid"]),
    async enterpriseDel(id) {
      try {
        const { res_info } = await this.delEnterpriseMchid({ id });
        if (res_info !== "ok") return;
        this.$message.success("删除成功");
        await this.init();
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      try {
        this.loading = true;
        const { data } = await this.detailEnterprise({
          id: this.$route.params.id,
        });
        this.detail = data;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.course-detail-title {
  margin: 10px;
  background: #fafafa;
  overflow: hidden;
}

.title {
  font-size: 18px;
  margin-top: 5px;
}

.price {
  margin-top: 45px;
  font-size: 16px;
}
</style>